/*
 * @Author: 吴永生 15770852798@163.com
 * @Date: 2023-12-08 15:34:56
 * @LastEditors: 吴永生 15770852798@163.com
 * @LastEditTime: 2023-12-08 22:50:01
 * @FilePath: /kuaichengji-web/src/config/constants.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export const isPro = process.env.DEPLOY_ENV === 'production';
export const isTest = process.env.DEPLOY_ENV === 'test';

// 后端服务域名
export const SERVER_URL = isPro ? 'https://api.basejy.com' : 'http://ttfs.154basejy.com';

// node服务，用于报告pdf文件导出
export const NODE_SERVER_URL = isPro
  ? 'https://api.basejy.com/kuaicj-pdf'
  : isTest
  ? 'http://ttfs.146basejy.com:8287'
  : 'http://localhost:3000'

// 项目部署的目录
export const DEPLOY_DIR = isTest ? '/kuaicj/' : '/';

// 相关h5页面访问地址
export const H5_STATIC_SERVER_URL = isPro
  ? 'https://h5.basejy.com'
  : 'https://tencent.dev-env.basejy.com';

// 微信公众号appid
export const WX_APPID = isPro ? 'wxae050b3b7db880a5' : 'wx99bc4493f44c706f';

// 微信小程序appid
export const WX_MP_APP_ID = 'wx2b5870e22df01aff';

// 项目访问地址
export const APP_URL = isPro
  ? 'https://kuaicj.basejy.com'
  : isTest
  ? 'http://v5.dev.basejy.com/kuaicj'
  : 'http://localhost:8000';

// 后端服务前缀
export const BASE_SYSTEM_USER = '/base-system-user';
export const SCHOOL_SERVICE = '/base-school';
export const TEACHER_SERVICE = '/base-school-teacher';
export const SUPPORT_SERVICE = '/ttfs-support';
export const SCHOOL_COURSE = '/base-school-course';
export const BASE_SCHOOL_COURSE = '/base-school-course';
export const BASE_SCHOOL_TEACHER = '/base-school-teacher';
export const BASE_SCHOOL = '/base-school';
export const SCHEDULING_ENGINE = '/schedule/engine/scheduleproject';
export const BASE_EXCEL_ENGINE = '/base-excel-engine';
export const BASE_ANALYSIS = '/base-analysis';
export const BASE_SUPPORT = '/base-support';
export const BASE_USER_BEHAVIOR = '/base-user-behavior';
export const BASE_SALES = '/base-sales';

// 前端localStorage相关Key
export const KUAICJ_USER_TOKEN = 'KUAICJ_USER_TOKEN'; // token
export const KUAICJ_USER_INFO = 'KUAICJ_USER_INFO'; // 用户信息
export const KUAICJ_INVITER_ID = 'KUAICJ_INVITER_ID'; // 邀请人id
export const KUAICJ_SCHOOL_INFO = 'KUAICJ_SCHOOL_INFO'; // 学校信息
export const KUAICJ_STEP_GUIDE = 'KUAICJ_STEP_GUIDE';
export const KUAICJ_DATA_EXAMPLE = 'KUAICJ_DATA_EXAMPLE';
export const KUAICJ_MENU_GUIDE = 'KUAICJ_MENU_GUIDE';
export const KUAICJ_VIP_RISE_PRICE_TIP = 'KUAICJ_VIP_RISE_PRICE_TIP';
export const KUAICJ_SCORE_ONLINE_EXAMPLE = 'KUAICJ_SCORE_ONLINE_EXAMPLE';
export const KUAICJ_TEMPLATE_COMMENT_GUIDE = 'KUAICJ_TEMPLATE_COMMENT_GUIDE';
export const KUAICJ_TEAM_ID = 'KUAICJ_TEAM_ID';
export const KUAICJ_TRAINING_CAMP = 'KUAICJ_TRAINING_CAMP'; // 训练营弹窗
export const SCREEN_SCALE_TIP_MODAL = 'SCREEN_SCALE_TIP_MODAL'; // 屏幕缩放提示弹窗
export const VISITOR_FROM = 'kuaicj_visitor_from';
export const VISITOR_REF = 'kuaicj_visitor_ref';
export const BATCH_COPY_IMPORT_TIP_NOT_ANYMORE = 'batch_copy_import_tip_not_anymore';
